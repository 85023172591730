@charset "utf-8";

:root {
    --palette-neutral-black: rgb(31, 31, 31);
    --palette-neutral-dark: rgb(51, 51, 51);
    --palette-neutral-quiet: rgb(102, 102, 102);
    --palette-neutral-quieter: rgb(153, 153, 153);
    --palette-neutral-quietest: rgb(204, 204, 204);
    --palette-neutral-white: rgb(255, 255, 255);
    --palette-neutral-lightGray1: rgb(250, 250, 250);
    --palette-neutral-lightGray2: rgb(242, 242, 242);
    --palette-neutral-lightGray3: rgb(232, 232, 232);
    --palette-neutral-lightGray4: rgb(224, 224, 224);
    --palette-opacity-darken1: rgba(0, 0, 0, 0.05);
    --palette-opacity-darken2: rgba(0, 0, 0, 0.1);
    --palette-opacity-darken3: rgba(0, 0, 0, 0.25);
    --palette-opacity-darken4: rgba(0, 0, 0, 0.5);
    --palette-opacity-lighten1: rgba(255, 255, 255, 0.05);
    --palette-opacity-lighten2: rgba(255, 255, 255, 0.1);
    --palette-opacity-lighten3: rgba(255, 255, 255, 0.25);
    --palette-opacity-lighten4: rgba(255, 255, 255, 0.5);
    --palette-blue-blue: rgb(22, 110, 225);
    --palette-blue-blueDusty: rgb(59, 102, 163);
    --palette-blue-blueDark1: rgb(13, 82, 172);
    --palette-blue-blueLight1: rgb(160, 198, 255);
    --palette-blue-blueLight2: rgb(209, 226, 255);
    --palette-blue-blueLight3: rgb(241, 245, 255);
    --palette-cyan-cyan: rgb(57, 202, 255);
    --palette-cyan-cyanDusty: rgb(16, 125, 163);
    --palette-cyan-cyanDark1: rgb(15, 104, 162);
    --palette-cyan-cyanLight1: rgb(136, 219, 255);
    --palette-cyan-cyanLight2: rgb(196, 236, 255);
    --palette-cyan-cyanLight3: rgb(227, 250, 253);
    --palette-teal-teal: rgb(1, 221, 213);
    --palette-teal-tealDusty: rgb(13, 127, 120);
    --palette-teal-tealDark1: rgb(23, 114, 110);
    --palette-teal-tealLight1: rgb(116, 235, 225);
    --palette-teal-tealLight2: rgb(193, 245, 240);
    --palette-teal-tealLight3: rgb(228, 251, 251);
    --palette-green-green: rgb(4, 138, 14);
    --palette-green-greenDusty: rgb(64, 124, 74);
    --palette-green-greenDark1: rgb(0, 100, 0);
    --palette-green-greenLight1: rgb(154, 224, 149);
    --palette-green-greenLight2: rgb(207, 245, 209);
    --palette-green-greenLight3: rgb(230, 252, 232);
    --palette-yellow-yellow: rgb(255, 186, 5);
    --palette-yellow-yellowDusty: rgb(162, 104, 17);
    --palette-yellow-yellowDark1: rgb(175, 96, 2);
    --palette-yellow-yellowLight1: rgb(255, 214, 107);
    --palette-yellow-yellowLight2: rgb(255, 234, 182);
    --palette-yellow-yellowLight3: rgb(255, 246, 221);
    --palette-orange-orange: rgb(213, 68, 1);
    --palette-orange-orangeDusty: rgb(148, 77, 55);
    --palette-orange-orangeDark1: rgb(170, 45, 0);
    --palette-orange-orangeLight1: rgb(255, 182, 142);
    --palette-orange-orangeLight2: rgb(255, 224, 204);
    --palette-orange-orangeLight3: rgb(255, 236, 227);
    --palette-red-red: rgb(220, 4, 59);
    --palette-red-redDusty: rgb(153, 69, 90);
    --palette-red-redDark1: rgb(177, 15, 65);
    --palette-red-redLight1: rgb(255, 166, 193);
    --palette-red-redLight2: rgb(255, 212, 224);
    --palette-red-redLight3: rgb(255, 242, 250);
    --palette-pink-pink: rgb(221, 4, 168);
    --palette-pink-pinkDusty: rgb(140, 63, 120);
    --palette-pink-pinkDark1: rgb(171, 10, 131);
    --palette-pink-pinkLight1: rgb(247, 151, 239);
    --palette-pink-pinkLight2: rgb(250, 210, 252);
    --palette-pink-pinkLight3: rgb(255, 241, 255);
    --palette-purple-purple: rgb(124, 55, 239);
    --palette-purple-purpleDusty: rgb(99, 73, 141);
    --palette-purple-purpleDark1: rgb(98, 49, 174);
    --palette-purple-purpleLight1: rgb(191, 174, 252);
    --palette-purple-purpleLight2: rgb(224, 218, 253);
    --palette-purple-purpleLight3: rgb(252, 243, 255);
    --palette-gray-gray: rgb(97, 102, 112);
    --palette-gray-grayDusty: rgb(83, 89, 101);
    --palette-gray-grayDark1: rgb(65, 69, 77);
    --palette-gray-grayLight1: rgb(196, 199, 205);
    --palette-gray-grayLight2: rgb(229, 233, 240);
    --palette-gray-grayLight3: rgb(242, 244, 248);
    --colors-foreground-default: var(--palette-neutral-dark);
    --colors-foreground-subtle: var(--palette-neutral-quiet);
    --colors-foreground-subtler: var(--palette-neutral-quieter);
    --colors-foreground-subtlest: var(--palette-neutral-quietest);
    --colors-hyperlink-default: var(--colors-foreground-default);
    --colors-hyperlink-subtle: var(--colors-foreground-subtle);
    --colors-hyperlink-primary: var(--palette-blue-blue);
    --colors-background-default: var(--palette-neutral-white);
    --colors-background-subtle: var(--palette-neutral-lightGray2);
    --colors-background-subtler: var(--palette-neutral-lightGray1);
    --colors-background-raised: var(--palette-neutral-white);
    --colors-background-backdropForModals: var(--palette-opacity-darken3);
    --colors-background-selected: var(--palette-opacity-darken1);
    --colors-background-selectedBlue: rgba(22, 110, 225, 0.05);
    --colors-border-default: var(--palette-opacity-darken2);
    --colors-border-subtle: var(--palette-opacity-darken1);
    --colors-border-focus: var(--palette-blue-blue);
}

/***
    The new CSS reset - version 1.8.5 (last updated 14.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
    cursor: revert;
}

/* For images to not be able to exceed their container */
img {
    max-inline-size: 100%;
    max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
    all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
    all: revert;
}

/* share styles */

/* System Fonts with system-ui */
body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

h1 {
    font-size: 1.5rem;
    font-weight: 200;
    margin: 1rem 0;
}
h2 {
    font-size: 1rem;
    margin: 1rem 0;
    font-weight: 400;
}

/* layout */

body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
}

#app {
    display: flex;
    flex-direction: row;
    height: 100vh;

    padding: 0;
    margin: 0;
}

header {
    min-width: 20em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

header h1 {
    margin-top: 0;
}

@media screen and (max-width: 40em) {
    #app {
        flex-direction: column;
    }
}

#main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1em;
}

.uppy-Container,
.uppy-Root,
.uppy-Dashboard {
    flex-grow: 1;
    max-width: 100%;
    height: 100%;
}

footer {
    padding: 1em;
}

footer p {
    margin: 0 0 1em;
}

footer p:last-child {
    margin-bottom: 0;
}

.frame {
    padding: 1em;
}

.message {
    width: 100%;
    max-width: 30em;
    margin: 2em auto;
    padding: 2em 1em;
    text-align: center;
    border-radius: 0.2962963em;
}

.link {
    background: url("asset/link.svg") no-repeat;
    width: 4em;
    height: 4em;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: transform 1s cubic-bezier(0.885, 0.01, 0.49, 0.995);
}

/* colours */

body {
    color: var(--col-wark-black);
}

header {
    background: var(--palette-green-greenDusty);
    color: white;
}

.header-footer {
    background: var(--palette-opacity-lighten1);
}

.message {
    background: var(--palette-opacity-darken1);
}

footer {
    background: var(--colors-background-subtle);
}

a {
    color: var(--colors-hyperlink-default);
}

a:hover {
    color: var(--colors-hyperlink-primary);
}

/* utility */

.shadow-elevation-low {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.32),
        0 0 2px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.08);
}

.shadow-elevation-low-hover:hover,
.shadow-elevation-low-hover-not-focused:not(:focus-within):hover {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.32),
        0 0 3px rgba(0, 0, 0, 0.11),
        0 1px 4px rgba(0, 0, 0, 0.12);
}

.shadow-elevation-low-top {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.32),
        0 0 2px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.08);
    clip-path: inset(-100vh 0 0 0);
}

.shadow-elevation-low-bottom {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.32),
        0 0 2px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.08);
    clip-path: inset(0 0 -100vh 0);
}

.shadow-elevation-medium,
.shadow-elevation-medium-hover:hover {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.2),
        0 0 2px rgba(0, 0, 0, 0.08),
        0 3px 4px rgba(0, 0, 0, 0.03),
        0 3px 16px rgba(0, 0, 0, 0.06);
}

.shadow-elevation-high,
.shadow-elevation-high-hover:hover {
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.24),
        0 0 2px rgba(0, 0, 0, 0.16),
        0 3px 4px rgba(0, 0, 0, 0.06),
        0 6px 8px rgba(0, 0, 0, 0.06),
        0 12px 16px rgba(0, 0, 0, 0.08),
        0 18px 32px rgba(0, 0, 0, 0.06);
}

.divider {
    border-top: 1px solid var(--colors-border-default);
}

/* animations */

@keyframes rotateAndSnap {
    0% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.885, 0.01, 0.49, 0.995);
    }
    25% {
        transform: rotate(90deg);
        animation-timing-function: cubic-bezier(0.885, 0.01, 0.49, 0.995);
    }
    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.885, 0.01, 0.49, 0.995);
    }
    75% {
        transform: rotate(270deg);
        animation-timing-function: cubic-bezier(0.885, 0.01, 0.49, 0.995);
    }
    100% {
        transform: rotate(360deg);
        animation-timing-function: cubic-bezier(0.885, 0.01, 0.49, 0.995);
    }
}

.rotates {
    animation: rotateAndSnap 4s infinite; /* You can adjust the time here for faster or slower rotations */
}

.rotates-fixed {
    animation-play-state: paused;
}
