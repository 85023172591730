:root {
  --palette-neutral-black: #1f1f1f;
  --palette-neutral-dark: #333;
  --palette-neutral-quiet: #666;
  --palette-neutral-quieter: #999;
  --palette-neutral-quietest: #ccc;
  --palette-neutral-white: #fff;
  --palette-neutral-lightGray1: #fafafa;
  --palette-neutral-lightGray2: #f2f2f2;
  --palette-neutral-lightGray3: #e8e8e8;
  --palette-neutral-lightGray4: #e0e0e0;
  --palette-opacity-darken1: #0000000d;
  --palette-opacity-darken2: #0000001a;
  --palette-opacity-darken3: #00000040;
  --palette-opacity-darken4: #00000080;
  --palette-opacity-lighten1: #ffffff0d;
  --palette-opacity-lighten2: #ffffff1a;
  --palette-opacity-lighten3: #ffffff40;
  --palette-opacity-lighten4: #ffffff80;
  --palette-blue-blue: #166ee1;
  --palette-blue-blueDusty: #3b66a3;
  --palette-blue-blueDark1: #0d52ac;
  --palette-blue-blueLight1: #a0c6ff;
  --palette-blue-blueLight2: #d1e2ff;
  --palette-blue-blueLight3: #f1f5ff;
  --palette-cyan-cyan: #39caff;
  --palette-cyan-cyanDusty: #107da3;
  --palette-cyan-cyanDark1: #0f68a2;
  --palette-cyan-cyanLight1: #88dbff;
  --palette-cyan-cyanLight2: #c4ecff;
  --palette-cyan-cyanLight3: #e3fafd;
  --palette-teal-teal: #01ddd5;
  --palette-teal-tealDusty: #0d7f78;
  --palette-teal-tealDark1: #17726e;
  --palette-teal-tealLight1: #74ebe1;
  --palette-teal-tealLight2: #c1f5f0;
  --palette-teal-tealLight3: #e4fbfb;
  --palette-green-green: #048a0e;
  --palette-green-greenDusty: #407c4a;
  --palette-green-greenDark1: #006400;
  --palette-green-greenLight1: #9ae095;
  --palette-green-greenLight2: #cff5d1;
  --palette-green-greenLight3: #e6fce8;
  --palette-yellow-yellow: #ffba05;
  --palette-yellow-yellowDusty: #a26811;
  --palette-yellow-yellowDark1: #af6002;
  --palette-yellow-yellowLight1: #ffd66b;
  --palette-yellow-yellowLight2: #ffeab6;
  --palette-yellow-yellowLight3: #fff6dd;
  --palette-orange-orange: #d54401;
  --palette-orange-orangeDusty: #944d37;
  --palette-orange-orangeDark1: #aa2d00;
  --palette-orange-orangeLight1: #ffb68e;
  --palette-orange-orangeLight2: #ffe0cc;
  --palette-orange-orangeLight3: #ffece3;
  --palette-red-red: #dc043b;
  --palette-red-redDusty: #99455a;
  --palette-red-redDark1: #b10f41;
  --palette-red-redLight1: #ffa6c1;
  --palette-red-redLight2: #ffd4e0;
  --palette-red-redLight3: #fff2fa;
  --palette-pink-pink: #dd04a8;
  --palette-pink-pinkDusty: #8c3f78;
  --palette-pink-pinkDark1: #ab0a83;
  --palette-pink-pinkLight1: #f797ef;
  --palette-pink-pinkLight2: #fad2fc;
  --palette-pink-pinkLight3: #fff1ff;
  --palette-purple-purple: #7c37ef;
  --palette-purple-purpleDusty: #63498d;
  --palette-purple-purpleDark1: #6231ae;
  --palette-purple-purpleLight1: #bfaefc;
  --palette-purple-purpleLight2: #e0dafd;
  --palette-purple-purpleLight3: #fcf3ff;
  --palette-gray-gray: #616670;
  --palette-gray-grayDusty: #535965;
  --palette-gray-grayDark1: #41454d;
  --palette-gray-grayLight1: #c4c7cd;
  --palette-gray-grayLight2: #e5e9f0;
  --palette-gray-grayLight3: #f2f4f8;
  --colors-foreground-default: var(--palette-neutral-dark);
  --colors-foreground-subtle: var(--palette-neutral-quiet);
  --colors-foreground-subtler: var(--palette-neutral-quieter);
  --colors-foreground-subtlest: var(--palette-neutral-quietest);
  --colors-hyperlink-default: var(--colors-foreground-default);
  --colors-hyperlink-subtle: var(--colors-foreground-subtle);
  --colors-hyperlink-primary: var(--palette-blue-blue);
  --colors-background-default: var(--palette-neutral-white);
  --colors-background-subtle: var(--palette-neutral-lightGray2);
  --colors-background-subtler: var(--palette-neutral-lightGray1);
  --colors-background-raised: var(--palette-neutral-white);
  --colors-background-backdropForModals: var(--palette-opacity-darken3);
  --colors-background-selected: var(--palette-opacity-darken1);
  --colors-background-selectedBlue: #166ee10d;
  --colors-border-default: var(--palette-opacity-darken2);
  --colors-border-subtle: var(--palette-opacity-darken1);
  --colors-border-focus: var(--palette-blue-blue);
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

h1 {
  margin: 1rem 0;
  font-size: 1.5rem;
  font-weight: 200;
}

h2 {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

#app {
  flex-direction: row;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
}

header {
  flex-direction: column;
  justify-content: space-between;
  min-width: 20em;
  display: flex;
}

header h1 {
  margin-top: 0;
}

@media screen and (max-width: 40em) {
  #app {
    flex-direction: column;
  }
}

#main {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

main {
  flex-direction: column;
  flex-grow: 1;
  padding: 1em;
  display: flex;
}

.uppy-Container, .uppy-Root, .uppy-Dashboard {
  flex-grow: 1;
  max-width: 100%;
  height: 100%;
}

footer {
  padding: 1em;
}

footer p {
  margin: 0 0 1em;
}

footer p:last-child {
  margin-bottom: 0;
}

.frame {
  padding: 1em;
}

.message {
  text-align: center;
  border-radius: .296296em;
  width: 100%;
  max-width: 30em;
  margin: 2em auto;
  padding: 2em 1em;
}

.link {
  background: url("link.f3042035.svg") no-repeat;
  width: 4em;
  height: 4em;
  margin: 0 auto;
  transition: transform 1s cubic-bezier(.885, .01, .49, .995);
  transform: rotate(0);
}

body {
  color: var(--col-wark-black);
}

header {
  background: var(--palette-green-greenDusty);
  color: #fff;
}

.header-footer {
  background: var(--palette-opacity-lighten1);
}

.message {
  background: var(--palette-opacity-darken1);
}

footer {
  background: var(--colors-background-subtle);
}

a {
  color: var(--colors-hyperlink-default);
}

a:hover {
  color: var(--colors-hyperlink-primary);
}

.shadow-elevation-low {
  box-shadow: 0 0 1px #00000052, 0 0 2px #00000014, 0 1px 3px #00000014;
}

.shadow-elevation-low-hover:hover, .shadow-elevation-low-hover-not-focused:not(:focus-within):hover {
  box-shadow: 0 0 1px #00000052, 0 0 3px #0000001c, 0 1px 4px #0000001f;
}

.shadow-elevation-low-top {
  -webkit-clip-path: inset(-100vh 0 0);
  clip-path: inset(-100vh 0 0);
  box-shadow: 0 0 1px #00000052, 0 0 2px #00000014, 0 1px 3px #00000014;
}

.shadow-elevation-low-bottom {
  -webkit-clip-path: inset(0 0 -100vh);
  clip-path: inset(0 0 -100vh);
  box-shadow: 0 0 1px #00000052, 0 0 2px #00000014, 0 1px 3px #00000014;
}

.shadow-elevation-medium, .shadow-elevation-medium-hover:hover {
  box-shadow: 0 0 1px #0003, 0 0 2px #00000014, 0 3px 4px #00000008, 0 3px 16px #0000000f;
}

.shadow-elevation-high, .shadow-elevation-high-hover:hover {
  box-shadow: 0 0 1px #0000003d, 0 0 2px #00000029, 0 3px 4px #0000000f, 0 6px 8px #0000000f, 0 12px 16px #00000014, 0 18px 32px #0000000f;
}

.divider {
  border-top: 1px solid var(--colors-border-default);
}

@keyframes rotateAndSnap {
  0% {
    animation-timing-function: cubic-bezier(.885, .01, .49, .995);
    transform: rotate(0);
  }

  25% {
    animation-timing-function: cubic-bezier(.885, .01, .49, .995);
    transform: rotate(90deg);
  }

  50% {
    animation-timing-function: cubic-bezier(.885, .01, .49, .995);
    transform: rotate(180deg);
  }

  75% {
    animation-timing-function: cubic-bezier(.885, .01, .49, .995);
    transform: rotate(270deg);
  }

  100% {
    animation-timing-function: cubic-bezier(.885, .01, .49, .995);
    transform: rotate(360deg);
  }
}

.rotates {
  animation: 4s infinite rotateAndSnap;
}

.rotates-fixed {
  animation-play-state: paused;
}

.uppy-Root {
  box-sizing: border-box;
  color: #333;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
  position: relative;
}

[dir="rtl"] .uppy-Root, .uppy-Root[dir="rtl"] {
  text-align: right;
}

.uppy-Root *, .uppy-Root :before, .uppy-Root :after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  all: initial;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
}

[dir="rtl"] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 8px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
}

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}

.uppy-c-textInput:focus {
  border-color: #2275d799;
  outline: none;
  box-shadow: 0 0 0 3px #2275d726;
}

[data-uppy-theme="dark"] .uppy-c-textInput {
  color: #eaeaea;
  background-color: #333;
  border-color: #333;
}

[data-uppy-theme="dark"] .uppy-c-textInput:focus {
  box-shadow: none;
  border-color: #525252;
}

.uppy-c-icon {
  fill: currentColor;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  overflow: hidden;
}

.uppy-c-btn {
  font-weight: 500;
  font-size: inherit;
  color: inherit;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .3s;
  display: inline-flex;
}

[dir="rtl"] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  color: #fff;
  background-color: #2275d7;
  border-radius: 4px;
  padding: 10px 18px;
  font-size: 14px;
}

.uppy-c-btn-primary:hover {
  background-color: #1b5dab;
}

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d766;
}

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-c-btn-link {
  color: #525252;
  background-color: #0000;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
}

.uppy-c-btn-link:hover {
  color: #333;
}

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}

[data-uppy-theme="dark"] .uppy-c-btn-link {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:hover {
  color: #939393;
}

.uppy-Informer {
  z-index: 1005;
  text-align: center;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.uppy-Informer span > div {
  margin-bottom: 6px;
}

.uppy-Informer-animated {
  z-index: -1000;
  opacity: 0;
  transition: all .3s ease-in;
  transform: translateY(350%);
}

.uppy-Informer p {
  color: #fff;
  background-color: #757575;
  border-radius: 18px;
  max-width: 90%;
  margin: 0;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}

.uppy-size--md .uppy-Informer p {
  max-width: 500px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Informer p {
  background-color: #333;
}

[dir="ltr"] .uppy-Informer p span {
  left: 3px;
}

[dir="rtl"] .uppy-Informer p span {
  right: 3px;
}

[dir="ltr"] .uppy-Informer p span {
  margin-left: -1px;
}

[dir="rtl"] .uppy-Informer p span {
  margin-right: -1px;
}

.uppy-Informer p span {
  color: #525252;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.uppy-Informer p span:hover {
  cursor: help;
}

.uppy-Informer p span:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Root [aria-label][role~="tooltip"] {
  position: relative;
}

.uppy-Root [aria-label][role~="tooltip"]:before, .uppy-Root [aria-label][role~="tooltip"]:after {
  z-index: 10;
  box-sizing: border-box;
  transform-origin: top;
  backface-visibility: hidden;
  opacity: 0;
  transition: all var(--microtip-transition-duration, .18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  pointer-events: none;
  will-change: transform;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.uppy-Root [aria-label][role~="tooltip"]:before {
  content: "";
  background-size: 100% !important;
}

.uppy-Root [aria-label][role~="tooltip"]:after {
  box-sizing: content-box;
  color: #fff;
  font-weight: var(--microtip-font-weight, normal);
  font-size: var(--microtip-font-size, 13px);
  white-space: nowrap;
  text-transform: var(--microtip-text-transform, none);
  content: attr(aria-label);
  background: #111111e6;
  border-radius: 4px;
  padding: .5em 1em;
}

.uppy-Root [aria-label][role~="tooltip"]:hover:before, .uppy-Root [aria-label][role~="tooltip"]:hover:after, .uppy-Root [aria-label][role~="tooltip"]:focus:before, .uppy-Root [aria-label][role~="tooltip"]:focus:after {
  opacity: 1;
  pointer-events: auto;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 18px;
  height: 6px;
  margin-bottom: 5px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:after {
  margin-bottom: 11px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover:after {
  transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:after {
  bottom: 100%;
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:after {
  bottom: 100%;
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover:after {
  transform: translate3d(-16px, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 18px;
  height: 6px;
  margin-top: 5px;
  margin-bottom: 0;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:after {
  margin-top: 11px;
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover:after {
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:after {
  top: 100%;
  transform: translate3d(calc(16px - 100%), -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:after {
  top: 100%;
  transform: translate3d(-16px, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover:after {
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  inset: 50% 100% auto auto;
  transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 6px;
  height: 18px;
  margin-bottom: 0;
  margin-right: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  margin-right: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 6px;
  height: 18px;
  margin-bottom: 0;
  margin-left: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  margin-left: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-size="small"]:after {
  white-space: initial;
  width: 80px;
}

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]:after {
  white-space: initial;
  width: 150px;
}

.uppy-Root [role~="tooltip"][data-microtip-size="large"]:after {
  white-space: initial;
  width: 260px;
}

.uppy-StatusBar {
  z-index: 1001;
  color: #fff;
  background-color: #fff;
  height: 46px;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
  transition: height .2s;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-StatusBar {
  background-color: #1f1f1f;
}

.uppy-StatusBar:before {
  content: "";
  background-color: #eaeaea;
  width: 100%;
  height: 2px;
  position: absolute;
  inset: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar:before {
  background-color: #757575;
}

.uppy-StatusBar[aria-hidden="true"] {
  height: 0;
  overflow-y: hidden;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  height: 65px;
}

[data-uppy-theme="dark"] .uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-StatusBar-progress {
  z-index: 1001;
  background-color: #2275d7;
  height: 2px;
  transition: background-color, width .3s ease-out;
  position: absolute;
}

.uppy-StatusBar-progress.is-indeterminate {
  background-image: linear-gradient(45deg, #0000004d 25%, #0000 25% 50%, #0000004d 50% 75%, #0000 75%, #0000);
  background-size: 64px 64px;
  animation: 1s linear infinite uppy-StatusBar-ProgressStripes;
}

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 64px 0;
  }
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

[dir="ltr"] .uppy-StatusBar-content {
  padding-left: 10px;
}

[dir="rtl"] .uppy-StatusBar-content {
  padding-right: 10px;
}

.uppy-StatusBar-content {
  z-index: 1002;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-content {
  padding-left: 15px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-content {
  padding-right: 15px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-content {
  color: #eaeaea;
}

[dir="ltr"] .uppy-StatusBar-status {
  padding-right: .3em;
}

[dir="rtl"] .uppy-StatusBar-status {
  padding-left: .3em;
}

.uppy-StatusBar-status {
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1;
  display: flex;
}

.uppy-StatusBar-statusPrimary button.uppy-StatusBar-details {
  margin-left: 5px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusPrimary {
  color: #eaeaea;
}

.uppy-StatusBar-statusSecondary {
  color: #757575;
  white-space: nowrap;
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusSecondary {
  color: #bbb;
}

[dir="ltr"] .uppy-StatusBar-statusSecondaryHint {
  margin-right: 5px;
}

[dir="rtl"] .uppy-StatusBar-statusSecondaryHint {
  margin-left: 5px;
}

.uppy-StatusBar-statusSecondaryHint {
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-right: 8px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-left: 8px;
}

[dir="ltr"] .uppy-StatusBar-statusIndicator {
  margin-right: 7px;
}

[dir="rtl"] .uppy-StatusBar-statusIndicator {
  margin-left: 7px;
}

.uppy-StatusBar-statusIndicator {
  color: #525252;
  position: relative;
  top: 1px;
}

.uppy-StatusBar-statusIndicator svg {
  vertical-align: text-bottom;
}

[dir="ltr"] .uppy-StatusBar-actions {
  right: 10px;
}

[dir="rtl"] .uppy-StatusBar-actions {
  left: 10px;
}

.uppy-StatusBar-actions {
  z-index: 1004;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  position: static;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #1f1f1f;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  flex-direction: column;
  height: 90px;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  flex-direction: row;
  height: 65px;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: column;
  justify-content: center;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  justify-content: initial;
  flex-direction: row;
}

.uppy-StatusBar-actionCircleBtn {
  cursor: pointer;
  opacity: .9;
  margin: 3px;
  line-height: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionCircleBtn:hover {
  opacity: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
  color: #2275d7;
  font-size: 10px;
  line-height: inherit;
  vertical-align: middle;
  display: inline-block;
}

.uppy-size--md .uppy-StatusBar-actionBtn {
  font-size: 11px;
}

.uppy-StatusBar-actionBtn--disabled {
  opacity: .4;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--disabled {
  opacity: .7;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry {
  margin-right: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry {
  margin-left: 6px;
}

.uppy-StatusBar-actionBtn--retry {
  color: #fff;
  background-color: #ff4b23;
  border-radius: 8px;
  height: 16px;
  padding: 1px 6px 3px 18px;
  line-height: 1;
  position: relative;
}

.uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionBtn--retry:hover {
  background-color: #f92d00;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry svg {
  left: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry svg {
  right: 6px;
}

.uppy-StatusBar-actionBtn--retry svg {
  position: absolute;
  top: 3px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  color: #fff;
  background-color: #1bb240;
  width: 100%;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #189c38;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1c8b37;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #18762f;
}

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: auto;
  padding: 13px 22px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  cursor: not-allowed;
  background-color: #1bb240;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1c8b37;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  color: #2275d7;
  background-color: #0000;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded, [dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-left: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  border-radius: 3px;
  padding-bottom: 1px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-actionBtn--uploadNewlyAdded, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  display: none;
}

.uppy-StatusBar-actionBtn--done {
  border-radius: 3px;
  padding: 7px 8px;
  line-height: 1;
}

.uppy-StatusBar-actionBtn--done:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--done::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--done:hover {
  color: #1b5dab;
}

.uppy-StatusBar-actionBtn--done:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done:focus {
  background-color: #333;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done {
  color: #02baf2;
}

.uppy-size--md .uppy-StatusBar-actionBtn--done {
  font-size: 14px;
}

.uppy-StatusBar-serviceMsg {
  color: #000;
  padding-left: 10px;
  font-size: 11px;
  line-height: 1.1;
}

.uppy-size--md .uppy-StatusBar-serviceMsg {
  padding-left: 15px;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-serviceMsg {
  color: #eaeaea;
}

.uppy-StatusBar-serviceMsg-ghostsIcon {
  vertical-align: text-bottom;
  opacity: .5;
  width: 10px;
  position: relative;
  top: 2px;
  left: 6px;
}

.uppy-size--md .uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 15px;
  top: 1px;
  left: 10px;
}

[dir="ltr"] .uppy-StatusBar-details {
  left: 2px;
}

[dir="rtl"] .uppy-StatusBar-details {
  right: 2px;
}

.uppy-StatusBar-details {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: help;
  -webkit-appearance: none;
  appearance: none;
  background-color: #939393;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
}

.uppy-StatusBar-details:after {
  word-wrap: break-word;
  line-height: 1.3;
}

[dir="ltr"] .uppy-StatusBar-spinner {
  margin-right: 10px;
}

[dir="rtl"] .uppy-StatusBar-spinner {
  margin-left: 10px;
}

.uppy-StatusBar-spinner {
  fill: #2275d7;
  animation-name: uppy-StatusBar-spinnerAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623;
}

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
  display: flex;
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list:after, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list:after {
  content: "";
  flex: auto;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 50%;
  margin: 0;
  position: relative;
}

.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--md .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 33.3333%;
}

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--lg .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 25%;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem:before, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected svg {
  opacity: .85;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--disabled {
  opacity: .5;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #93939333;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #eaeaea33;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: #000000b3;
  width: 30%;
  height: 30%;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: #fffc;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  text-align: center;
  border-radius: 4px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  position: absolute;
  inset: 7px;
  overflow: hidden;
}

@media (hover: none) {
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
    display: block;
  }
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px #aae1ffb3;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner img {
  object-fit: cover;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-author {
  color: #fff;
  background: #0000004d;
  width: 100%;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-author:hover, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-author:hover {
  background: #0006;
  text-decoration: underline;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox {
  z-index: 1002;
  opacity: 0;
  background-color: #2275d7;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 16px;
  right: 16px;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="ltr"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  left: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="rtl"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  right: 7px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  width: 12px;
  height: 7px;
  top: 8px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--is-checked, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--is-checked {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author {
  display: block;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus {
  outline: none;
}

:is(.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner) {
  border: 0;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  align-items: center;
  margin: 0;
  padding: 7px 15px;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: .6;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-right: 15px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-left: 15px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border: 1px solid #2275d7;
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  left: 3px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  right: 3px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  opacity: 0;
  width: 9px;
  height: 5px;
  top: 4px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border-color: #02baf2b3;
  box-shadow: 0 0 0 3px #02baf233;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked:after {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding: 2px;
  display: flex;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  text-decoration: underline;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-left: 8px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-right: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-left: 7px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
}

.uppy-ProviderBrowserItem-checkbox {
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
}

.uppy-ProviderBrowserItem-checkbox:disabled {
  cursor: default;
}

.uppy-ProviderBrowserItem-checkbox:after {
  cursor: pointer;
  content: "";
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  position: absolute;
  transform: rotate(-45deg);
}

.uppy-ProviderBrowserItem-checkbox:disabled:after {
  cursor: default;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}

.uppy-size--md .uppy-SearchProvider-input {
  margin-bottom: 20px;
}

.uppy-SearchProvider-input::-webkit-search-cancel-button {
  display: none;
}

.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}

.uppy-size--md .uppy-SearchProvider-searchButton {
  padding: 13px 30px;
}

.uppy-DashboardContent-panelBody {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth, .uppy-Provider-error, .uppy-Provider-loading, .uppy-Provider-empty {
  color: #939393;
  flex-flow: column wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
}

.uppy-Provider-authTitle {
  color: #757575;
  text-align: center;
  max-width: 500px;
  margin-bottom: 30px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}

.uppy-size--md .uppy-Provider-authTitle {
  font-size: 20px;
}

[data-uppy-theme="dark"] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-btn-google {
  background: #4285f4;
  align-items: center;
  display: flex;
  padding: 8px 12px !important;
}

.uppy-Provider-btn-google:hover {
  background-color: #1266f1;
}

.uppy-Provider-btn-google:focus {
  outline: none;
  box-shadow: 0 0 0 3px #4285f466;
}

.uppy-Provider-btn-google svg {
  margin-right: 8px;
}

[dir="ltr"] .uppy-Provider-breadcrumbs {
  text-align: left;
}

[dir="rtl"] .uppy-Provider-breadcrumbs {
  text-align: right;
}

.uppy-Provider-breadcrumbs {
  color: #525252;
  flex: 1;
  margin-bottom: 10px;
  font-size: 12px;
}

.uppy-size--md .uppy-Provider-breadcrumbs {
  margin-bottom: 0;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

[dir="ltr"] .uppy-Provider-breadcrumbsIcon {
  margin-right: 4px;
}

[dir="rtl"] .uppy-Provider-breadcrumbsIcon {
  margin-left: 4px;
}

.uppy-Provider-breadcrumbsIcon {
  color: #525252;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

.uppy-Provider-breadcrumbsIcon svg {
  fill: #525252;
  width: 13px;
  height: 13px;
}

.uppy-Provider-breadcrumbs button {
  line-height: inherit;
  border-radius: 3px;
  padding: 4px;
  display: inline-block;
}

.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}

.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}

.uppy-Provider-breadcrumbs button:hover {
  color: #1b5dab;
}

.uppy-Provider-breadcrumbs button:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}

.uppy-Provider-breadcrumbs button:not(:last-of-type) {
  text-decoration: underline;
}

.uppy-Provider-breadcrumbs button:last-of-type {
  color: #333;
  cursor: normal;
  pointer-events: none;
  font-weight: 500;
}

.uppy-Provider-breadcrumbs button:hover {
  cursor: pointer;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  flex-direction: column;
  flex: 1;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.uppy-ProviderBrowser-user {
  color: #333;
  margin: 0 8px 0 0;
  font-weight: 500;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

[dir="ltr"] .uppy-ProviderBrowser-user:after {
  left: 4px;
}

[dir="rtl"] .uppy-ProviderBrowser-user:after {
  right: 4px;
}

.uppy-ProviderBrowser-user:after {
  color: #939393;
  content: "·";
  font-weight: normal;
  position: relative;
}

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  z-index: 1001;
  color: #757575;
  background-color: #fafafa;
  padding: 7px 15px;
  font-size: 12px;
  line-height: 1.4;
}

.uppy-size--md .uppy-ProviderBrowser-headerBar {
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  justify-content: center;
  display: block;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  vertical-align: middle;
  flex: none;
  display: inline-block;
}

.uppy-ProviderBrowser-searchFilter {
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-ProviderBrowser-searchFilterInput {
  padding-left: 30px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchFilterInput, [dir="ltr"] .uppy-ProviderBrowser-searchFilterInput {
  padding-right: 30px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchFilterInput {
  padding-left: 30px;
}

.uppy-ProviderBrowser-searchFilterInput {
  z-index: 1001;
  color: #333;
  background-color: #eaeaea;
  border: 0;
  border-radius: 4px;
  outline: 0;
  width: 100%;
  height: 30px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4;
}

.uppy-ProviderBrowser-searchFilterInput::-webkit-search-cancel-button {
  display: none;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchFilterInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchFilterInput:focus {
  background-color: #cfcfcf;
  border: 0;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchFilterInput:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-ProviderBrowser-searchFilterIcon {
  left: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchFilterIcon {
  right: 16px;
}

.uppy-ProviderBrowser-searchFilterIcon {
  z-index: 1002;
  color: #757575;
  width: 12px;
  height: 12px;
  position: absolute;
}

.uppy-ProviderBrowser-searchFilterInput::placeholder {
  color: #939393;
  opacity: 1;
}

[dir="ltr"] .uppy-ProviderBrowser-searchFilterReset {
  right: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchFilterReset {
  left: 16px;
}

.uppy-ProviderBrowser-searchFilterReset {
  z-index: 1002;
  color: #939393;
  cursor: pointer;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  padding: 6px;
  position: absolute;
}

.uppy-ProviderBrowser-searchFilterReset:focus {
  outline: none;
}

.uppy-ProviderBrowser-searchFilterReset::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-searchFilterReset:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-ProviderBrowser-searchFilterReset:hover {
  color: #757575;
}

.uppy-ProviderBrowser-searchFilterReset svg {
  vertical-align: text-top;
}

.uppy-ProviderBrowser-userLogout {
  color: #2275d7;
  line-height: inherit;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
}

.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}

.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-userLogout:hover {
  color: #1b5dab;
}

.uppy-ProviderBrowser-userLogout:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  flex: 1;
  position: relative;
}

.uppy-ProviderBrowser-list {
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}

.uppy-ProviderBrowser-footer {
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  align-items: center;
  height: 65px;
  padding: 0 15px;
  display: flex;
}

[dir="ltr"] .uppy-ProviderBrowser-footer button {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-footer button {
  margin-left: 8px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-Dashboard-Item-previewInnerWrap {
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2px #0006;
}

.uppy-size--md .uppy-Dashboard-Item-previewInnerWrap {
  box-shadow: 0 1px 2px #00000026;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-previewInnerWrap {
  box-shadow: none;
}

.uppy-Dashboard-Item-previewInnerWrap:after {
  z-index: 1001;
  content: "";
  background-color: #000000a6;
  display: none;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink {
  z-index: 1002;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #76abe9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #016c8d;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 10px;
}

.uppy-Dashboard-Item-progress {
  z-index: 1002;
  color: #fff;
  text-align: center;
  transition: all .35 ease;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-Item-progressIndicator {
  opacity: .9;
  color: #fff;
  width: 38px;
  height: 38px;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-Item-progressIndicator {
  width: 55px;
  height: 55px;
}

button.uppy-Dashboard-Item-progressIndicator {
  cursor: pointer;
}

button.uppy-Dashboard-Item-progressIndicator:focus {
  outline: none;
}

button.uppy-Dashboard-Item-progressIndicator::-moz-focus-inner {
  border: 0;
}

button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--bg, button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--retry {
  fill: #76abe9;
}

.uppy-Dashboard-Item-progressIcon--circle {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-progressIcon--bg {
  stroke: #fff6;
}

.uppy-Dashboard-Item-progressIcon--progress {
  stroke: #fff;
  transition: stroke-dashoffset .5s ease-out;
}

.uppy-Dashboard-Item-progressIcon--play {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--cancel {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--pause {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--check {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--retry {
  fill: #fff;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: -8px;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: -8px;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: initial;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: initial;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  transform: initial;
  width: auto;
  top: -9px;
}

.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
}

.uppy-size--md .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 28px;
  height: 28px;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  opacity: 1;
  width: 18px;
  height: 18px;
}

.uppy-size--md .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 22px;
  height: 22px;
}

.uppy-Dashboard-Item.is-processing .uppy-Dashboard-Item-progress {
  opacity: 0;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfo {
  padding-right: 5px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfo {
  padding-left: 5px;
}

[dir="ltr"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-right: 10px;
}

[dir="rtl"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-left: 10px;
}

[dir="ltr"] .uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-right: 15px;
}

[dir="rtl"] .uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-left: 15px;
}

.uppy-Dashboard-Item-name {
  word-wrap: anywhere;
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-name {
  color: #eaeaea;
}

.uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-name {
  font-size: 14px;
  line-height: 1.4;
}

.uppy-Dashboard-Item-fileName {
  align-items: baseline;
  display: flex;
}

.uppy-Dashboard-Item-fileName button {
  margin-left: 5px;
}

.uppy-Dashboard-Item-author {
  color: #757575;
  vertical-align: bottom;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.uppy-Dashboard-Item-author a {
  color: #757575;
}

.uppy-Dashboard-Item-status {
  color: #757575;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-status {
  color: #bbb;
}

.uppy-Dashboard-Item-statusSize {
  text-transform: uppercase;
  vertical-align: bottom;
  margin-bottom: 5px;
  display: inline-block;
}

.uppy-Dashboard-Item-reSelect {
  color: #2275d7;
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
}

.uppy-Dashboard-Item-errorMessage {
  color: #a51523;
  background-color: #fdeff1;
  padding: 5px 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.3;
}

.uppy-Dashboard-Item-errorMessageBtn {
  color: #a51523;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  text-decoration: underline;
}

.uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-size--md .uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  border-top: 1px solid #f7c2c8;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 6px 8px;
  line-height: 1.4;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  border: 1px solid #f7c2c8;
  border-radius: 3px;
  display: inline-block;
  position: static;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-Dashboard-Item-action {
  color: #939393;
  cursor: pointer;
}

.uppy-Dashboard-Item-action:focus {
  outline: none;
}

.uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-Item-action:hover {
  color: #1f1f1f;
  opacity: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action {
  color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:hover {
  color: #eaeaea;
}

.uppy-Dashboard-Item-action--remove {
  color: #1f1f1f;
  opacity: .95;
}

.uppy-Dashboard-Item-action--remove:hover {
  color: #000;
  opacity: 1;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item-action--remove {
  right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item-action--remove {
  left: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove {
  z-index: 1002;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove:focus {
  border-radius: 50%;
}

[dir="ltr"] .uppy-Dashboard--singleFile.uppy-size--height-md .uppy-Dashboard-Item-action--remove {
  right: 8px;
}

[dir="rtl"] .uppy-Dashboard--singleFile.uppy-size--height-md .uppy-Dashboard-Item-action--remove {
  left: 8px;
}

.uppy-Dashboard--singleFile.uppy-size--height-md .uppy-Dashboard-Item-action--remove {
  position: absolute;
  top: 8px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove {
  color: #525252;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove:hover {
  color: #333;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile.uppy-size--height-md) .uppy-Dashboard-Item-actionWrapper {
  align-items: center;
  display: flex;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile.uppy-size--height-md) .uppy-Dashboard-Item-action {
  width: 22px;
  height: 22px;
  margin-left: 3px;
  padding: 3px;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile.uppy-size--height-md) .uppy-Dashboard-Item-action:focus {
  border-radius: 3px;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink, .uppy-size--md .uppy-Dashboard-Item-action--edit {
  width: 16px;
  height: 16px;
  padding: 0;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink:focus, .uppy-size--md .uppy-Dashboard-Item-action--edit:focus {
  border-radius: 3px;
}

.uppy-Dashboard-Item {
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  padding: 10px;
  display: flex;
}

[dir="ltr"] .uppy-Dashboard:not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item {
  padding-right: 0;
}

[dir="rtl"] .uppy-Dashboard:not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item {
  padding-left: 0;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item {
  border-bottom: 1px solid #333;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item {
  float: left;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item {
  float: right;
}

.uppy-size--md .uppy-Dashboard-Item {
  border-bottom: 0;
  width: calc(33.333% - 30px);
  height: 215px;
  margin: 5px 15px;
  padding: 0;
  display: block;
  position: relative;
}

.uppy-size--lg .uppy-Dashboard-Item {
  width: calc(25% - 30px);
  height: 190px;
  margin: 5px 15px;
  padding: 0;
}

.uppy-size--xl .uppy-Dashboard-Item {
  width: calc(20% - 30px);
  height: 210px;
  padding: 0;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item {
  border-bottom: 0;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 100%;
  padding: 15px;
  display: flex;
  position: relative;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-previewInnerWrap {
  opacity: .2;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-name {
  opacity: .7;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  z-index: 1005;
  opacity: .5;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='39' viewBox='0 0 35 39'%3E%3Cpath d='M1.708 38.66c1.709 0 3.417-3.417 6.834-3.417 3.416 0 5.125 3.417 8.61 3.417 3.348 0 5.056-3.417 8.473-3.417 4.305 0 5.125 3.417 6.833 3.417.889 0 1.709-.889 1.709-1.709v-19.68C34.167-5.757 0-5.757 0 17.271v19.68c0 .82.888 1.709 1.708 1.709zm8.542-17.084a3.383 3.383 0 01-3.417-3.416 3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.417 3.417 3.383 3.383 0 01-3.417 3.416zm13.667 0A3.383 3.383 0 0120.5 18.16a3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.416 3.417 3.383 3.383 0 01-3.416 3.416z' fill='%2523000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 10px;
  background-repeat: no-repeat;
  background-size: 25px;
  position: absolute;
  inset: 0;
}

.uppy-size--md .uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  background-position: 50%;
  background-size: 40px;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  background-position: 50%;
  background-size: 30%;
}

.uppy-Dashboard-Item-preview {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-Item-preview {
  width: 100%;
  height: 140px;
}

.uppy-size--lg .uppy-Dashboard-Item-preview {
  height: 120px;
}

.uppy-size--xl .uppy-Dashboard-Item-preview {
  height: 140px;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-preview {
  flex-grow: 1;
  width: 100%;
  max-height: 75%;
}

.uppy-Dashboard--singleFile.uppy-size--md .uppy-Dashboard-Item-preview {
  max-height: 100%;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 8px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 8px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 12px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 12px;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfoAndButtons, .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfoAndButtons {
  align-items: flex-start;
  padding: 9px 0 0;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfoAndButtons {
  flex-grow: 0;
  width: 100%;
}

.uppy-Dashboard-Item-fileInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.uppy-Dashboard-Item-actionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-Dashboard-Item.is-inprogress .uppy-Dashboard-Item-previewInnerWrap:after, .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-previewInnerWrap:after {
  display: block;
}

.uppy-Dashboard-Item.is-inprogress:not(.is-resumable) .uppy-Dashboard-Item-action--remove {
  display: none;
}

[dir="ltr"] .uppy-Dashboard-Item-errorDetails {
  left: 2px;
}

[dir="rtl"] .uppy-Dashboard-Item-errorDetails {
  right: 2px;
}

.uppy-Dashboard-Item-errorDetails {
  color: #fff;
  text-align: center;
  cursor: help;
  -webkit-appearance: none;
  appearance: none;
  background-color: #939393;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  position: relative;
  top: 0;
}

.uppy-Dashboard-Item-errorDetails:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Dashboard-FileCard {
  z-index: 1005;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 4px #0000001a;
}

.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 0;
  display: flex;
}

.uppy-Dashboard-FileCard-preview {
  border-bottom: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  height: 60%;
  min-height: 0;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-preview {
  background-color: #333;
  border-bottom: 0;
}

.uppy-Dashboard-FileCard-preview img.uppy-Dashboard-Item-previewImg {
  object-fit: cover;
  border-radius: 3px;
  flex: none;
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 3px 20px #00000026;
}

[dir="ltr"] .uppy-Dashboard-FileCard-edit {
  right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-edit {
  left: 10px;
}

.uppy-Dashboard-FileCard-edit {
  color: #fff;
  background-color: #00000080;
  border-radius: 50px;
  padding: 7px 15px;
  font-size: 13px;
  position: absolute;
  top: 10px;
}

.uppy-Dashboard-FileCard-edit:focus {
  outline: none;
}

.uppy-Dashboard-FileCard-edit::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-FileCard-edit:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-FileCard-edit:hover {
  background-color: #000c;
}

.uppy-Dashboard-FileCard-info {
  -webkit-overflow-scrolling: touch;
  flex-grow: 0;
  flex-shrink: 0;
  height: 40%;
  padding: 30px 20px 20px;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-info {
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-fieldset {
  border: 0;
  max-width: 640px;
  margin: auto auto 12px;
  padding: 0;
  font-size: 0;
}

.uppy-Dashboard-FileCard-label {
  color: #525252;
  vertical-align: middle;
  width: 22%;
  font-size: 12px;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-FileCard-label {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-label {
  color: #eaeaea;
}

.uppy-Dashboard-FileCard-input {
  vertical-align: middle;
  width: 78%;
  display: inline-block;
}

.uppy-Dashboard-FileCard-actions {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-FileCard-actions {
  height: 65px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-actions {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

[dir="ltr"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-left: 10px;
}

.uppy-transition-slideDownUp-enter {
  opacity: .01;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, -105%, 0);
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: .01;
  transform: translate3d(0, -105%, 0);
}

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
}

.uppy-Dashboard--modal {
  z-index: 1001;
}

.uppy-Dashboard--modal[aria-hidden="true"] {
  display: none;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-slideDownAndFadeIn--small;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
    animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-slideDownAndFadeIn;
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-fadeIn;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-slideUpFadeOut--small;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
    animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-slideUpFadeOut;
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) uppy-Dashboard-fadeOut;
}

.uppy-Dashboard-isFixed {
  height: 100vh;
  overflow: hidden;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1001;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

.uppy-Dashboard-inner {
  background-color: #f4f4f4;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  outline: none;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-inner {
  min-height: auto;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard-inner {
    width: 650px;
    height: 500px;
  }
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1002;
}

[data-uppy-theme="dark"] .uppy-Dashboard-inner {
  background-color: #1f1f1f;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-inner {
  cursor: not-allowed;
}

.uppy-Dashboard-innerWrap {
  opacity: 0;
  border-radius: 5px;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-innerWrap {
  opacity: .6;
  filter: grayscale();
  -webkit-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.uppy-Dashboard--isDisabled .uppy-ProviderIconBg {
  fill: #9f9f9f;
}

.uppy-Dashboard--isDisabled [disabled], .uppy-Dashboard--isDisabled [aria-disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  border: none;
  position: fixed;
  inset: 35px 15px 15px;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px 4px #00000026;
  }
}

[dir="ltr"] .uppy-Dashboard-close {
  right: -2px;
}

[dir="rtl"] .uppy-Dashboard-close {
  left: -2px;
}

.uppy-Dashboard-close {
  z-index: 1005;
  color: #ffffffe6;
  cursor: pointer;
  font-size: 27px;
  display: block;
  position: absolute;
  top: -33px;
}

.uppy-Dashboard-close:focus {
  outline: none;
}

.uppy-Dashboard-close::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-close:focus {
  color: #8cb8ed;
}

@media only screen and (min-width: 820px) {
  [dir="ltr"] .uppy-Dashboard-close {
    right: -35px;
  }

  [dir="rtl"] .uppy-Dashboard-close {
    left: -35px;
  }

  .uppy-Dashboard-close {
    font-size: 35px;
    top: -10px;
  }
}

.uppy-Dashboard-serviceMsg {
  z-index: 1004;
  background-color: #fffbf7;
  border-top: 1px solid #edd4b9;
  border-bottom: 1px solid #edd4b9;
  padding: 12px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
  top: -1px;
}

.uppy-size--md .uppy-Dashboard-serviceMsg {
  font-size: 14px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg {
  color: #eaeaea;
  background-color: #1f1f1f;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.uppy-Dashboard-serviceMsg-title {
  margin-bottom: 4px;
  padding-left: 42px;
  line-height: 1;
  display: block;
}

.uppy-Dashboard-serviceMsg-text {
  padding: 0 15px;
}

.uppy-Dashboard-serviceMsg-actionBtn {
  color: #2275d7;
  font-weight: inherit;
  font-size: inherit;
  vertical-align: initial;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg-actionBtn {
  color: #02baf2e6;
}

.uppy-Dashboard-serviceMsg-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.uppy-Dashboard-AddFiles {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  border: 1px dashed #dfdfdf;
  border-radius: 3px;
  height: calc(100% - 14px);
  margin: 7px;
}

.uppy-Dashboard-AddFilesPanel .uppy-Dashboard-AddFiles {
  border: none;
  height: calc(100% - 54px);
}

.uppy-Dashboard--modal .uppy-Dashboard-AddFiles {
  border-color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles {
  border-color: #757575;
}

.uppy-Dashboard-AddFiles-info {
  margin-top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: block;
}

.uppy-size--md .uppy-Dashboard-AddFiles-info {
  padding-top: 30px;
  padding-bottom: 0;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-info {
  margin-top: 0;
}

.uppy-Dashboard-browse {
  color: #2275d7e6;
  cursor: pointer;
}

.uppy-Dashboard-browse:focus {
  outline: none;
}

.uppy-Dashboard-browse::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #2275d7;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse {
  color: #02baf2e6;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse:hover, [data-uppy-theme="dark"] .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #02baf2;
}

.uppy-Dashboard-browseBtn {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.uppy-size--md .uppy-Dashboard-browseBtn {
  width: auto;
  margin: 15px auto;
  padding: 13px 44px;
  font-size: 15px;
}

.uppy-Dashboard-AddFiles-list {
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: 2px;
  padding: 2px 0;
  display: flex;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-AddFiles-list {
  flex-flow: wrap;
  flex: none;
  justify-content: center;
  max-width: 600px;
  margin-top: 15px;
  padding-top: 0;
  overflow-y: visible;
}

.uppy-DashboardTab {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}

[data-uppy-theme="dark"] .uppy-DashboardTab {
  border-bottom: 1px solid #333;
}

.uppy-size--md .uppy-DashboardTab {
  width: initial;
  border-bottom: none;
  margin-bottom: 10px;
  display: inline-block;
}

.uppy-DashboardTab-btn {
  color: #525252;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 15px;
}

.uppy-DashboardTab-btn:focus {
  outline: none;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-right: 1px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-left: 1px;
}

.uppy-size--md .uppy-DashboardTab-btn {
  border-radius: 5px;
  flex-direction: column;
  width: 86px;
  padding: 10px 3px;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn {
  color: #eaeaea;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardTab-btn:hover {
  background-color: #e9ecef;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:hover {
  background-color: #333;
}

.uppy-DashboardTab-btn:active, .uppy-DashboardTab-btn:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:active, [data-uppy-theme="dark"] .uppy-DashboardTab-btn:focus {
  background-color: #525252;
}

.uppy-DashboardTab-btn svg {
  vertical-align: text-top;
  max-width: 100%;
  max-height: 100%;
  transition: transform .15s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

[dir="ltr"] .uppy-DashboardTab-inner {
  margin-right: 10px;
}

[dir="rtl"] .uppy-DashboardTab-inner {
  margin-left: 10px;
}

.uppy-DashboardTab-inner {
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  box-shadow: 0 1px 1px #0000001a, 0 1px 2px #0000001a, 0 2px 3px #00000005;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-inner {
  margin-right: 0;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-inner {
  margin-left: 0;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-inner {
  background-color: #323232;
  box-shadow: 0 1px 1px #0003, 0 1px 2px #0003, 0 2px 3px #00000014;
}

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 400;
}

.uppy-size--md .uppy-DashboardTab-name {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 15px;
}

.uppy-DashboardTab-iconMyDevice {
  color: #2275d7;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconMyDevice {
  color: #02baf2;
}

.uppy-DashboardTab-iconBox {
  color: #0061d5;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconBox {
  color: #eaeaea;
}

.uppy-DashboardTab-iconDropbox {
  color: #0061fe;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconDropbox {
  color: #eaeaea;
}

.uppy-DashboardTab-iconUnsplash {
  color: #111;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconUnsplash {
  color: #eaeaea;
}

.uppy-DashboardTab-iconScreenRec {
  color: #2c3e50;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconScreenRec {
  color: #eaeaea;
}

.uppy-DashboardTab-iconAudio {
  color: #8030a3;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-iconAudio {
  color: #bf6ee3;
}

.uppy-Dashboard-input {
  z-index: -1;
  opacity: 0;
  width: .1px;
  height: .1px;
  position: absolute;
  overflow: hidden;
}

.uppy-DashboardContent-bar {
  z-index: 1004;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.uppy-size--md .uppy-DashboardContent-bar {
  height: 50px;
  padding: 0 15px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-bar {
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
}

.uppy-DashboardContent-title {
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 170px;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.uppy-size--md .uppy-DashboardContent-title {
  max-width: 300px;
  font-size: 14px;
  line-height: 50px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-title {
  color: #eaeaea;
}

[dir="ltr"] .uppy-DashboardContent-back, [dir="ltr"] .uppy-DashboardContent-save {
  margin-left: -6px;
}

[dir="rtl"] .uppy-DashboardContent-back, [dir="rtl"] .uppy-DashboardContent-save {
  margin-right: -6px;
}

.uppy-DashboardContent-back, .uppy-DashboardContent-save {
  color: #2275d7;
  font-size: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  margin: 0;
  padding: 7px 6px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  outline: none;
}

:is(.uppy-DashboardContent-back::-moz-focus-inner, .uppy-DashboardContent-save::-moz-focus-inner) {
  border: 0;
}

.uppy-DashboardContent-back:hover, .uppy-DashboardContent-save:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back:focus, [data-uppy-theme="dark"] .uppy-DashboardContent-save:focus {
  background-color: #333;
}

.uppy-size--md .uppy-DashboardContent-back, .uppy-size--md .uppy-DashboardContent-save {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back, [data-uppy-theme="dark"] .uppy-DashboardContent-save {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore {
  margin-right: -5px;
}

[dir="rtl"] .uppy-DashboardContent-addMore {
  margin-left: -5px;
}

.uppy-DashboardContent-addMore {
  color: #2275d7;
  font-size: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  width: 29px;
  height: 29px;
  margin: 0;
  padding: 7px 8px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1;
}

.uppy-DashboardContent-addMore:focus {
  outline: none;
}

.uppy-DashboardContent-addMore::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardContent-addMore:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-addMore:focus {
  background-color: #dfe6f1;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-left: -8px;
}

.uppy-size--md .uppy-DashboardContent-addMore {
  width: auto;
  height: auto;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore svg {
  margin-right: 4px;
}

[dir="rtl"] .uppy-DashboardContent-addMore svg {
  margin-left: 4px;
}

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
}

.uppy-size--md .uppy-DashboardContent-addMore svg {
  width: 11px;
  height: 11px;
}

.uppy-DashboardContent-addMoreCaption {
  display: none;
}

.uppy-size--md .uppy-DashboardContent-addMoreCaption {
  display: inline;
}

.uppy-DashboardContent-panel {
  z-index: 1005;
  background-color: #f5f5f5;
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.uppy-Dashboard-AddFilesPanel {
  z-index: 1005;
  background: linear-gradient(0deg, #fafafa 35%, #fafafad9 100%);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #00000026;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFilesPanel {
  background-color: #333;
  background-image: linear-gradient(0deg, #1f1f1f 35%, #1f1f1fd9 100%);
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  filter: blur(2px);
}

.uppy-Dashboard-progress {
  width: 100%;
  height: 12%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.uppy-Dashboard-filesContainer {
  flex: 1;
  margin: 0;
  position: relative;
  overflow-y: hidden;
}

.uppy-Dashboard-filesContainer:after {
  clear: both;
  content: "";
  display: table;
}

.uppy-Dashboard-files {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  margin: 0;
  padding: 0 0 10px;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-filesInner {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.uppy-Dashboard-dropFilesHereHint {
  z-index: 2000;
  color: #757575;
  text-align: center;
  visibility: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  font-size: 16px;
  display: flex;
  position: absolute;
  inset: 7px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-dropFilesHereHint {
  color: #bbb;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%2302BAF2' fill-rule='nonzero'/%3E%3C/svg%3E");
  border-color: #02baf2;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-serviceMsg {
  opacity: .15;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-AddFiles {
  opacity: .03;
}

.uppy-Dashboard-AddFiles-title {
  color: #000;
  text-align: inline-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.35;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title {
  text-align: center;
  max-width: 480px;
  margin-top: 5px;
  padding: 0 35px;
  font-size: 21px;
  font-weight: 400;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  text-align: center;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles-title {
  color: #eaeaea;
}

.uppy-Dashboard-AddFiles-title button {
  font-weight: 500;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title button {
  font-weight: 400;
}

.uppy-Dashboard-note {
  color: #757575;
  text-align: center;
  max-width: 350px;
  margin: auto;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1.25;
}

.uppy-size--md .uppy-Dashboard-note {
  max-width: 600px;
  line-height: 1.35;
}

[data-uppy-theme="dark"] .uppy-Dashboard-note {
  color: #cfcfcf;
}

a.uppy-Dashboard-poweredBy {
  color: #939393;
  text-align: center;
  margin-top: 8px;
  font-size: 11px;
  text-decoration: none;
  display: inline-block;
}

.uppy-Dashboard-poweredByIcon {
  vertical-align: text-top;
  opacity: .9;
  fill: none;
  stroke: #939393;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
}

.uppy-Dashboard-Item-previewIcon {
  z-index: 100;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-size--md .uppy-Dashboard-Item-previewIcon {
  width: 38px;
  height: 38px;
}

.uppy-Dashboard-Item-previewIcon svg {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-previewIcon {
  width: 100%;
  max-width: 60%;
  height: 100%;
  max-height: 60%;
}

.uppy-Dashboard-Item-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-previewIconWrap {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-previewIconBg {
  filter: drop-shadow(0 1px 1px #0000001a);
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-upload {
  width: 50px;
  height: 50px;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-upload {
  width: 60px;
  height: 60px;
}

.uppy-Dashboard-upload .uppy-c-icon {
  width: 50%;
  position: relative;
  top: 1px;
}

[dir="ltr"] .uppy-Dashboard-uploadCount {
  right: -12px;
}

[dir="rtl"] .uppy-Dashboard-uploadCount {
  left: -12px;
}

.uppy-Dashboard-uploadCount {
  color: #fff;
  background-color: #1bb240;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 8px;
  line-height: 16px;
  position: absolute;
  top: -12px;
}

.uppy-size--md .uppy-Dashboard-uploadCount {
  width: 18px;
  height: 18px;
  font-size: 9px;
  line-height: 18px;
}

/*# sourceMappingURL=index.8e545aee.css.map */
